import { Heading } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  ResponsePreviousReservation,
  ResponseUpcomingReservation,
} from "../types/bcgBackendResponses";
import { Course } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import useDrawer from "../util/useDrawer";
import BasicTable, { Column } from "./BasicTable";
import CancelReservationModal from "./CancelReservationModal";
import { FloatingContainerClear } from "./FloatingContainer";
import logger from "../util/logger";

const UpcomingReservations: FC<{
  headers: Record<string, string | number>;
  courses: Course[];
}> = ({ headers, courses }) => {
  const { isLoading, call } = useAxiosRequest();
  const [cancellingReservation, setCancellingReservation] =
    useState<ResponseUpcomingReservation.UpcomingReservation>();
  const [reservations, setReservations] =
    useState<ResponseUpcomingReservation.UpcomingReservation[]>();

  const getMyReservations = useCallback(async () => {
    if (isLoading || !headers) return;
    try {
      const res = await call<{
        myUpcomingReservations: ResponseUpcomingReservation.UpcomingReservation[];
        myPreviousReservations: ResponsePreviousReservation.PreviousReservation[];
      }>("/reservations", "POST", {
        headers,
      });
      setReservations(res?.data.myUpcomingReservations);
    } catch (err) {
      console.error(err);
      logger.error("Error getting reservations", { error: err });
    }
  }, [call, isLoading, headers]);

  const resetCancellingReservation = () => {
    setCancellingReservation(undefined);
  };

  const {
    drawer: cancelReservationDrawer,
    onOpen: cancelReservationOnOpen,
    onClose: cancelReservationOnClose,
  } = useDrawer(
    <CancelReservationModal
      reservation={cancellingReservation}
      onCancelComplete={() => {
        cancelReservationOnClose();
        resetCancellingReservation();
        getMyReservations();
      }}
      courses={courses}
    />,
    "Confirm Reservation Cancelation",
    resetCancellingReservation
  );

  const columns: Column[] = [
    {
      label: "Tee Time",
      key: "startTime",
      type: "date",
    },
    {
      label: "Courses",
      key: "courseName",
      type: "text",
    },
    {
      label: "Players",
      key: "numberOfPlayer",
      type: "number",
    },
    {
      label: "Actions",
      key: "actions",
      type: "icon",
      iconProps: {
        actions: [
          {
            icon: "delete",
            onClick: (row) => {
              cancelReservationOnOpen();
              setCancellingReservation(
                reservations?.find((r) => r.teeSheetId === row.id)
              );
            },
          },
        ],
      },
    },
  ];

  const rows = useMemo(() => {
    return reservations?.map((r) => {
      return {
        startTime: moment(r.startTime).format("MM/DD hh:mm a"),
        courseName: r.courseName,
        numberOfPlayer: r.numberOfPlayer,
        id: r.teeSheetId,
      };
    });
  }, [reservations]);

  useEffect(() => {
    getMyReservations();
  }, []);

  return (
    <FloatingContainerClear>
      <Heading size="sm">Upcoming Reservations</Heading>
      <BasicTable
        variant="simple"
        columns={columns}
        rows={rows}
        dataLoading={isLoading}
        noRowsMessage="No Upcoming Reservations"
      />
      {cancelReservationDrawer}
    </FloatingContainerClear>
  );
};

export default UpcomingReservations;
