import { Heading } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import { Course } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import useDrawer from "../util/useDrawer";
import BasicTable, { Column } from "./BasicTable";
import BookTeeTimeModal from "./BookTeeTimeModal";
import { FloatingContainerClear } from "./FloatingContainer";
import logger from "../util/logger";

const TodaysAvailableTeeTimes: FC<{
  headers: Record<string, string | number>;
  courses: Course[];
}> = ({ headers, courses }) => {
  const { isLoading, call } = useAxiosRequest();
  const [teeTimes, setTeeTimes] =
    useState<ResponseAvailableTeeTimes.AvailableTeeTimes[]>();
  const [selectedTeeTime, setSelectedTeeTime] =
    useState<ResponseAvailableTeeTimes.AvailableTeeTimes>();
  const isTomorrow = () => {
    let day = moment().tz("America/New_York");
    // is after 7 pm
    if (
      day.isAfter(moment().tz("America/New_York").hour(19).minute(0).second(0))
    ) {
      return true;
    }
    return false;
  };

  const getAvailableTeeTimes = useCallback(async () => {
    let day = moment().tz("America/New_York");
    if (isTomorrow()) {
      day = moment().tz("America/New_York").add(1, "days");
    }

    if (courses.length === 0 || isLoading) return;
    try {
      const resp = await call<ResponseAvailableTeeTimes.AvailableTeeTimes[]>(
        "/tee-times",
        "POST",
        {
          headers,
          id: localStorage.getItem("tee_time_user_id"),
          startDate: day,
          teeOffTimeMin: 5,
          teeOffTimeMax: 21,
          numberOfPlayers: 0,
          courseIds: courses.map((option) => option.id),
        }
      );
      if (resp) {
        setTeeTimes(resp.data);
      }
    } catch (err) {
      console.error(err);
      logger.error("Error getting available tee times", { error: err });
    }
  }, [courses, isLoading, call, headers]);

  const resetSelectedTeeTime = () => {
    setSelectedTeeTime(undefined);
  };

  const { drawer, onOpen, onClose } = useDrawer(
    <BookTeeTimeModal
      courseIds={courses.map((option) => option.id)}
      onBookComplete={() => {
        resetSelectedTeeTime();
        getAvailableTeeTimes();
        onClose();
      }}
      teeTime={selectedTeeTime}
    />,
    "Confirm Booking",
    resetSelectedTeeTime
  );

  const columns: Column[] = [
    {
      label: "Time",
      key: "startTime",
      type: "string",
    },
    {
      label: "Course",
      key: "courseName",
      type: "string",
    },
    {
      label: "Players",
      key: "availableParticipantNo",
      type: "string",
    },
    {
      label: "Actions",
      key: "actions",
      type: "icon",
      iconProps: {
        actions: [
          {
            icon: "add",
            onClick: (row) => {
              setSelectedTeeTime(
                teeTimes?.find((t) => t.teeSheetId === row.id)
              );
              onOpen();
            },
          },
        ],
      },
    },
  ];

  const rows = useMemo(() => {
    return teeTimes?.map((r) => {
      return {
        startTime: moment(r.startTime).format("hh:mm a"),
        courseName: r.courseName,
        availableParticipantNo: r.availableParticipantNo.length,
        id: r.teeSheetId,
      };
    });
  }, [teeTimes]);

  useEffect(() => {
    getAvailableTeeTimes();
  }, []);

  return (
    <FloatingContainerClear>
      <Heading size="sm">
        {isTomorrow() ? "Tomorrow's" : "Today's"} Tee Times
      </Heading>
      <BasicTable
        variant="simple"
        columns={columns}
        rows={rows}
        dataLoading={isLoading}
        noRowsMessage="No Tee Times"
      />
      {drawer}
    </FloatingContainerClear>
  );
};

export default TodaysAvailableTeeTimes;
