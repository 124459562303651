import { Flex } from "@chakra-ui/react";
import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react";

import AccountInformationModal from "../components/AccountInformationModal";
import { User } from "../types/user";
import { useBcg } from "../util/BcgProvider";
import useModal from "../util/useModal";
import LoadingModal from "../components/LoadingModal";
import NavBar from "../components/NavBar";
import useAxios from "../util/axiosHook";
import logger from "../util/logger";

const oneMinute = 60 * 1000;
const BCGProtectedPage: FC<PropsWithChildren> = ({ children }) => {
  const [
    { loading: isLoadingBcgHeaders, error: errorBcgHeaders },
    callBcgHeaders,
  ] = useAxios<Record<string, string | number>>({
    path: "/bcg-login",
    method: "post",
    lazy: true,
  });

  const [{ loading: isLoadingCourses, error: errorCourses }, callCourses] =
    useAxios<
      {
        id: number;
        name: string;
        holes: number;
      }[]
    >({
      path: "/courses",
      method: "get",
      lazy: true,
    });

  const [
    { loading: isLoadingAutoLogin, error: errorAutoLogin },
    callAutoLogin,
  ] = useAxios<User>({
    path: "/auto-login",
    method: "post",
    lazy: true,
  });
  const [headerInterval, setHeaderInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const { headers, setHeaders, setCourses, courses, user, setUser } = useBcg();
  const { modal, onClose, onOpen } = useModal(
    <AccountInformationModal user={user} setUser={setUser} />
  );
  const {
    modal: loadingModal,
    onClose: onCloseLoadingModal,
    onOpen: onOpenLoadingModal,
  } = useModal(<LoadingModal />, "sm");

  const setBcgHeaders = useCallback(async () => {
    if (isLoadingBcgHeaders) return;
    const cachedHeaders = getCachedHeaders();
    if (cachedHeaders) {
      return setHeaders(cachedHeaders);
    }
    if (user?.accountInfo) {
      try {
        const respHeaders = await callBcgHeaders("/bcg-login", "POST", {
          id: user.id,
        });
        if (respHeaders?.data) {
          localStorage.setItem(
            "tee_time_bcg_headers",
            JSON.stringify({
              ...respHeaders.data,
              remainingMilliseconds: Date.now() + oneMinute * 10,
            })
          );
          setHeaders(respHeaders.data);
        }
      } catch (err) {
        console.error(err);
        logger.error("Error getting BCG headers", { error: err });
      }
    }
  }, [callBcgHeaders, setHeaders, user, isLoadingBcgHeaders]);

  const setBcgCourses = useCallback(async () => {
    if (courses.length > 0) return;
    try {
      const res = await callCourses("/courses", "GET", {});
      if (res?.data) {
        setCourses(res.data);
      }
    } catch (err) {
      console.error(err);
      logger.error("Error getting courses", { error: err });
    }
  }, [callCourses, courses, setCourses]);

  useEffect(() => {
    if (isLoadingAutoLogin) return;
    if (errorAutoLogin) return;
    if (user) return;
    const id = localStorage.getItem("tee_time_user_id") ?? "";
    callAutoLogin(`/auto-login`, "POST", { id })
      .then((res) => {
        setUser(res?.data);
      })
      .catch((err) => {
        console.error(err);
        logger.error("Error auto logging in", { error: err });
      });
  }, [callAutoLogin, errorAutoLogin, isLoadingAutoLogin, setUser, user]);

  useEffect(() => {
    if (!user) {
      return onClose();
    }
    if (user?.accountInfo) {
      return onClose();
    }
    return onOpen();
  }, [onClose, onOpen, user]);

  useEffect(() => {
    if (errorBcgHeaders) return;
    if (isLoadingBcgHeaders) return;
    if (!headers) {
      setBcgHeaders();
    }
  }, [setBcgHeaders, headers, errorBcgHeaders, isLoadingBcgHeaders]);

  useEffect(() => {
    if (errorCourses) return;
    if (isLoadingCourses) return;

    setBcgCourses();
  }, [setBcgCourses, errorCourses, isLoadingCourses]);

  useEffect(() => {
    if (!isLoadingCourses && !isLoadingBcgHeaders && !isLoadingAutoLogin) {
      return onCloseLoadingModal();
    }
    return onOpenLoadingModal();
  }, [
    onOpenLoadingModal,
    onCloseLoadingModal,
    isLoadingCourses,
    isLoadingBcgHeaders,
    isLoadingAutoLogin,
  ]);

  const isValidHeader = (headers?: Record<string, string | number>) => {
    if (!headers) return false;
    if (headers.remainingMilliseconds === undefined) return false;
    let remaining = headers.remainingMilliseconds as number;
    if (remaining - Date.now() <= 0) return false;
    return true;
  };

  useEffect(() => {
    if (isLoadingBcgHeaders) return;
    if (!headers && headerInterval) {
      clearInterval(headerInterval);
      setHeaderInterval(null);
    }

    if (
      headers &&
      headers.remainingMilliseconds !== undefined &&
      !headerInterval
    ) {
      setHeaderInterval(
        setInterval(() => {
          let remaining = headers.remainingMilliseconds as number;
          if (remaining - Date.now() <= 0) {
            localStorage.removeItem("tee_time_bcg_headers");
            setHeaders(undefined);
          }
        }, 1000)
      );
    }
    if (headers && headers.remainingMilliseconds === undefined) {
      if (headerInterval) {
        clearTimeout(headerInterval);
        setHeaderInterval(null);
      }
      setHeaders(undefined);
    }
  }, [
    headers,
    setHeaders,
    setHeaderInterval,
    headerInterval,
    isLoadingBcgHeaders,
  ]);
  return (
    <Flex>
      {loadingModal}
      {modal}
      {user?.accountInfo &&
      headers &&
      isValidHeader(headers) &&
      courses &&
      courses.length > 0 ? (
        <Flex flexDirection="column" w="100%">
          <NavBar />
          <Flex flexDirection="column" w="100%" p="10px">
            {children}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default BCGProtectedPage;

export const getCachedHeaders = () => {
  const bcgHeaders = localStorage.getItem("tee_time_bcg_headers");
  try {
    if (bcgHeaders) {
      const parsedBcgHeaders = JSON.parse(bcgHeaders);
      if (parsedBcgHeaders) {
        return parsedBcgHeaders;
      }
    }
  } catch (err) {
    console.error(err, bcgHeaders);
    logger.error("Error parsing BCG headers", { error: err });
    return null;
  }
};
