import { Flex } from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";

import AvailableTeeTimes from "../components/AvailableTeeTimes";
import TeeTimeSearchForm from "../components/TeeTimeSearchForm";
import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import { useBcg } from "../util/BcgProvider";

const TeeTimes: FC = () => {
  const { courses } = useBcg();
  const [teeTimes, setTeeTimes] =
    useState<ResponseAvailableTeeTimes.AvailableTeeTimes[]>();
  const onFormComplete = (
    teeTimes: ResponseAvailableTeeTimes.AvailableTeeTimes[]
  ) => {
    setTeeTimes(teeTimes);
  };
  const options = useMemo(() => {
    return courses.map((c) => ({ label: c.name, value: `${c.id}` }));
  }, [courses]);
  if (!options.length) return null;
  return (
    <Flex flexDirection={"column"} w="100%" alignItems={"center"} gap={"10px"}>
      <TeeTimeSearchForm
        url="/tee-times"
        onFormComplete={onFormComplete}
        hasMaxDate
        title="Search Available Tee Times"
      />
      {teeTimes && teeTimes.length === 0 ? "No Tee Times Available" : null}
      <AvailableTeeTimes
        teeTimes={teeTimes}
        courseIds={options.map((o) => parseInt(o.value))}
      />
    </Flex>
  );
};

export default TeeTimes;
