import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const [id, setId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const id = localStorage.getItem("tee_time_user_id");
    if (!id) {
      navigate("/login", { replace: true });
    } else {
      setId(id);
    }
  }, [navigate]);

  return id ? children : null;
};

export default ProtectedRoute;
