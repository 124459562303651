import { FC } from "react";
import FormInputField from "./FormInputField";
import { Button, Flex, Heading, Image } from "@chakra-ui/react";
import GolferImage from "../assets/golfer_silhouette_transparent_background.png";
import { FloatingContainerGrey } from "./FloatingContainer";
const LoginForm: FC<{
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}> = ({ handleSubmit, isLoading }) => {
  return (
    <FloatingContainerGrey>
      <Flex direction="column" align="center" w="100%" p={8} m={4} pb={2}>
        <Image src={GolferImage} boxSize={"100px"} />
        <Heading size="lg" mb={6}>
          Welcome to Red Tees
        </Heading>
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Flex flexDirection={"column"} rowGap={4} w="100%">
            <FormInputField label="Email" inputType="email" name="email" />
            <FormInputField
              label="Password"
              inputType="password"
              name="password"
            />
            <Button
              type="submit"
              isLoading={isLoading}
              loadingText="Logging In"
              variant={"primary"}
              alignSelf={"flex-end"}
              mt={6}
            >
              Login
            </Button>
          </Flex>
        </form>
      </Flex>
    </FloatingContainerGrey>
  );
};

export default LoginForm;
