import { FC, PropsWithChildren, ReactNode } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
const AccordionSelect: FC<
  PropsWithChildren<{
    buttonTitle: ReactNode;
    isOpenDefault?: boolean;
    width?: string;
  }>
> = ({ buttonTitle, isOpenDefault = false, width, children }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: isOpenDefault });
  return (
    <Accordion
      allowToggle
      borderRadius={10}
      defaultIndex={isOpenDefault ? 0 : undefined}
      overflow={"hidden"}
      variant={"primary"}
      borderColor={isOpen ? "glassBackground" : "transparent"}
      backgroundColor={isOpen ? "glassBackground" : "transparent"}
      width={width}
      //   _hover={{
      //     backgroundColor: "rgba(20, 16, 16, 0.2)",
      //   }}
    >
      <AccordionItem>
        <h2>
          <AccordionButton onClick={onToggle}>
            <Box as="span" flex="1" textAlign="left">
              {buttonTitle}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionSelect;
