import { defineStyle } from "@chakra-ui/react";

const primary = defineStyle({
  icon: {
    color: "white",
    backgroundColor: "primaryRed",
    borderColor: "primaryRed",
  },
  control: {
    _checked: {
      color: "white",
      backgroundColor: "primaryRed",
      borderColor: "primaryRed",
    },
  },
});

export const Checkbox = {
  variants: {
    primary,
  },
};
