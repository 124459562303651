import { extendTheme } from "@chakra-ui/react";
import { Button } from "./Button";
import { Slider } from "./Slider";
import { Checkbox } from "./Checkbox";
import { Accordion } from "./Accordion";
import { Table } from "./Table";
import { Text } from "./Text";

const primaryRed = "#F56565";
const activeRed = "rgb(244, 85, 85)";
const glassBackground = "rgba(255, 255, 255, 0.18)";
export const hoverGlassBackground = "rgba(20, 16, 16, 0.18)";
const greyText = "rgba(255, 255, 255, 0.5)";
const greyBackground = "rgba(170, 170, 170, .55)";

export const theme = extendTheme({
  colors: {
    primaryRed,
    activeRed,
    glassBackground,
    hoverGlassBackground,
    greyText,
    greyBackground,
  },
  fonts: {
    heading: "Clarkson, sans-serif",
    body: "Clarkson, sans-serif",
  },
  styles: {
    global: {
      "html, body": {
        position: "absolute",
        color: "white",
        width: "100vw",
        height: "100vh",
      },
    },
  },
  components: {
    Button,
    Slider,
    Checkbox,
    Text,
    Accordion,
    Table,
  },
});
