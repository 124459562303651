import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { useCallback, useState } from "react";

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:8000";
const useAxiosRequest: () => {
  isLoading: boolean;
  call: <T>(
    url: string,
    method: Method,
    data: any
  ) => Promise<AxiosResponse<T, any> | undefined>;
  error: AxiosError | undefined;
} = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const call = useCallback(async (url: string, method: Method, data: any) => {
    setIsLoading(true);
    try {
      const response = await axios(baseUrl + url, { method, data });
      return response;
    } catch (err) {
      setError(err as AxiosError);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, []);
  return { isLoading, error, call };
};

export default useAxiosRequest;
