import { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { useBcg } from "../util/BcgProvider";
import useModal from "../util/useModal";
import LoadingModal from "../components/LoadingModal";

const Logout: FC = () => {
  const navigate = useNavigate();
  const { setHeaders, setCourses, setUser } = useBcg();
  const { modal, onClose, onOpen } = useModal(<LoadingModal />, "sm");
  useEffect(() => {
    onOpen();
    localStorage.removeItem("tee_time_user_id");
    localStorage.removeItem("tee_time_bcg_headers");
    setUser(undefined);
    setHeaders(undefined);
    setCourses([]);
    onClose();
    navigate("/login", { replace: true });
  }, [navigate, onClose, onOpen, setCourses, setHeaders, setUser]);

  return modal;
};

export default Logout;
