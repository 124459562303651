import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import { FC } from "react";

import { FloatingContainerClear } from "../components/FloatingContainer";
import TodaysAvailableTeeTimes from "../components/TodaysAvailableTeeTimes";
import UpcomingReservations from "../components/UpcomingTeeTimes";
import UpcomingTickets from "../components/UpcomingTickets";
import { useBcg } from "../util/BcgProvider";

const Home: FC = () => {
  const { user, courses, headers } = useBcg();
  return (
    <Flex flexDirection={"column"} w="100%" alignItems={"center"} gap="4">
      <FloatingContainerClear w={"100%"} borderRadius={"15px"}>
        {user ? (
          <>
            <Heading size="sm">
              Welcome back {user.accountInfo.firstName}{" "}
              {user.accountInfo.lastName}
            </Heading>
          </>
        ) : (
          <>
            <Skeleton height="20px" />
          </>
        )}
      </FloatingContainerClear>
      {user ? <UpcomingTickets user={user} courses={courses} /> : null}
      {headers ? (
        <UpcomingReservations headers={headers} courses={courses} />
      ) : null}
      {headers ? (
        <TodaysAvailableTeeTimes headers={headers} courses={courses} />
      ) : null}
    </Flex>
  );
};

export default Home;
