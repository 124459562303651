import { defineStyle } from "@chakra-ui/react";

const primary = defineStyle({
  root: {
    borderColor: "transparent",
    backgroundColor: "transparent",
    _expanded: {
      backgroundColor: "glassBackground",
      borderColor: "glassBackground",
    },
    _selected: {
      backgroundColor: "glassBackground",
      borderColor: "glassBackground",
    },
    _focus: {
      backgroundColor: "glassBackground",
      borderColor: "glassBackground",
    },
    _active: {
      backgroundColor: "glassBackground",
      borderColor: "glassBackground",
    },
    _checked: {
      backgroundColor: "glassBackground",
      borderColor: "glassBackground",
    },
  },
  container: {
    borderColor: "transparent",
    backgroundColor: "transparent",
  },
});

export const Accordion = {
  variants: {
    primary,
  },
};
