import { FC } from "react";

import FormInputField from "./FormInputField";
import { Button, Flex } from "@chakra-ui/react";
import FormCheckboxField from "./FormCheckboxField";

const AccountInformationForm: FC<{
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}> = ({ handleSubmit, isLoading }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Flex flexDirection={"column"} rowGap={5}>
        <Flex flexDirection={"column"} rowGap={3}>
          <FormInputField
            label="Username"
            inputType="text"
            helperText="Bergen County Golf Username"
            name="username"
          />
          <FormInputField
            label="Password"
            inputType="password"
            helperText="Bergen County Golf Password"
            name="password"
          />
        </Flex>
        <Button
          variant={"primary"}
          type="submit"
          isLoading={isLoading}
          isDisabled={isLoading}
          alignSelf={"flex-end"}
          my="20px"
        >
          Add Golfer Information
        </Button>
      </Flex>
    </form>
  );
};

export default AccountInformationForm;
