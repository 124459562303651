import {
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

import AccountInformationForm from "./AccountInformationForm";
import useAxiosRequest from "../util/useAxiosRequest";
import { User } from "../types/user";
import { backdropFilter } from "../themes/Button";
import logger from "../util/logger";

const AccountInformationModal: FC<{
  user?: User;
  setUser: (user: User) => void;
}> = ({ user, setUser }) => {
  const { isLoading, call } = useAxiosRequest();
  if (!user) return null;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    try {
      const response = await call<{
        id: string;
        golferUsername: string;
        golferPassword: string;
        createdAt: Date;
        updatedAt: Date;
        userId: string;
        senior: boolean;
      }>("/account-info", "POST", {
        ...data,
        id: user.id,
      });
      if (response?.data && response.data.id) {
        if (user.id === response.data.userId) {
          setUser({
            ...user,
            accountInfo: {
              golferUsername: response.data.golferUsername,
            },
          });
        }
      }
    } catch (err) {
      console.error(err);
      logger.error("Error updating account information", { error: err });
    }
  };
  return (
    <>
      <ModalOverlay />
      <ModalContent bgColor={"greyBackground"} backdropFilter={backdropFilter}>
        <ModalCloseButton />
        <ModalHeader>
          {" "}
          <Heading size="lg" mt={6}>
            Add Golfer Information
          </Heading>
        </ModalHeader>

        <ModalBody>
          <AccountInformationForm
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default AccountInformationModal;
