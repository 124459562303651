import { FC } from "react";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";
import useAxiosRequest from "../util/useAxiosRequest";
import { Flex, useMediaQuery } from "@chakra-ui/react";
import logger from "../util/logger";

const Login: FC = () => {
  const isMobileView = useMediaQuery("(max-width: 600px)")[0];
  const navigate = useNavigate();
  const { isLoading, call } = useAxiosRequest();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    logger.info("Attempting to login", { user: data.email });
    try {
      const response = await call<{ id: string }>("/login", "POST", data);
      if (response && response.data) {
        if (response.data.id) {
          localStorage.setItem("tee_time_user_id", response.data.id);
          navigate("/", { relative: "path" });
        }
        logger.info("Login successful", { response: response.data });
      }
    } catch (err) {
      console.error(err);
      logger.error("Error logging in", { error: err });
    }
  };
  // Form to login
  return (
    <Flex
      flexDirection={"column"}
      w="100%"
      alignItems={"center"}
      p={isMobileView ? 2 : 10}
    >
      <LoginForm handleSubmit={handleSubmit} isLoading={isLoading} />
    </Flex>
  );
};

export default Login;
