import { Button, Heading, useToast } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { onFormComplete } from "../pages/Tickets";
import { Ticket } from "../types/tickets";
import { User } from "../types/user";
import { Course } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import useDrawer from "../util/useDrawer";
import BasicTable, { Column } from "./BasicTable";
import CancelTicketModal from "./CancelTicketModal";
import { FloatingContainerClear } from "./FloatingContainer";
import TeeTimeSearchForm, { formatTime } from "./TeeTimeSearchForm";

const UpcomingTickets: FC<{ user: User; courses: Course[] }> = ({
  user,
  courses,
}) => {
  const { isLoading, call } = useAxiosRequest();
  const [tickets, setTickets] = useState<Ticket[]>();
  const [editingTicket, setEditingTicket] = useState<Ticket>();
  const [deletingTicket, setDeletingTicket] = useState<Ticket>();
  const toast = useToast();
  const getMyTickets = useCallback(async () => {
    if (isLoading) return;
    const t = await call<Ticket[]>(`/user/${user.id}/tickets`, "GET", {});
    setTickets(t?.data);
  }, [call, isLoading, user]);

  const resetEditingTicket = () => {
    setEditingTicket(undefined);
  };

  const resetDeletingTicket = () => {
    setDeletingTicket(undefined);
  };

  const {
    drawer: editTicketDrawer,
    onOpen: editTicketOnOpen,
    onClose: editTicketOnClose,
  } = useDrawer(
    <TeeTimeSearchForm
      url={editingTicket ? `/ticket/${editingTicket?.id}/edit` : "/ticket"}
      onFormComplete={() => {
        editTicketOnClose();
        resetEditingTicket();
        onFormComplete(toast, getMyTickets);
      }}
      hasMaxDate={false}
      title=""
      ticket={editingTicket}
    />,
    "Ticket",
    resetEditingTicket
  );

  const {
    drawer: cancelTicketDrawer,
    onOpen: cancelTicketOnOpen,
    onClose: cancelTicketOnClose,
  } = useDrawer(
    <CancelTicketModal
      ticket={deletingTicket}
      onCancelComplete={() => {
        cancelTicketOnClose();
        resetDeletingTicket();
        getMyTickets();
      }}
      courses={courses}
    />,
    "Confirm Ticket Cancellation",
    resetDeletingTicket
  );

  useEffect(() => {
    getMyTickets();
  }, []);

  const getCourseName = useCallback(
    (courseId: number) => {
      const course = courses?.find((c) => c.id === courseId);
      if (!course) return "Unknown Course";
      const split = course.name.split(" ");
      if (split.length === 1) return split[0].charAt(0).toUpperCase();
      if (split.length === 2)
        return split[0].charAt(0).toUpperCase() + " " + split[1];
      if (split.length === 3)
        return (
          split[0].charAt(0).toUpperCase() +
          split[1].charAt(0).toUpperCase() +
          " " +
          split[2]
        );
      if (split.length === 4)
        return (
          split[0].charAt(0).toUpperCase() +
          split[1].charAt(0).toUpperCase() +
          " " +
          split[2].charAt(0).toUpperCase() +
          split[3]
        );
    },
    [courses]
  );

  const filteredTickets = useMemo(() => {
    return tickets?.filter((t) => !t.isBooked && !t.isCancelled);
  }, [tickets]);

  const columns: Column[] = [
    {
      label: "Date",
      key: "date",
      type: "string",
    },
    {
      label: "Time Range",
      key: "timeRange",
      type: "string",
    },
    {
      label: "Courses",
      key: "courses",
      type: "string",
    },
    {
      label: "Players",
      key: "players",
      type: "string",
    },
    {
      label: "Actions",
      key: "actions",
      type: "string",
      iconProps: {
        actions: [
          {
            icon: "edit",
            onClick: (row: any) => {
              setEditingTicket(tickets?.find((t) => t.id === row.id));
              editTicketOnOpen();
            },
          },
          {
            icon: "delete",
            onClick: (row: any) => {
              setDeletingTicket(tickets?.find((t) => t.id === row.id));
              cancelTicketOnOpen();
            },
          },
        ],
      },
    },
  ];

  const rows = useMemo(() => {
    return filteredTickets?.map((ticket) => {
      return {
        date: moment(ticket.searchDate).format("MM/DD"),
        timeRange: `${formatTime(ticket.teeOffTimeMin)} - ${formatTime(
          ticket.teeOffTimeMax
        )}`,
        courses: ticket.courseIds.map(getCourseName).join(", "),
        players: ticket.numberOfPlayers,
        id: ticket.id,
      };
    });
  }, [filteredTickets, getCourseName]);

  return (
    <FloatingContainerClear>
      <Heading size="sm">Upcoming Tickets</Heading>
      <BasicTable
        variant="simple"
        columns={columns}
        rows={rows}
        dataLoading={isLoading}
        noRowsMessage="No Upcoming Tickets"
      />

      <Button
        variant={"gradient"}
        alignSelf={"flex-end"}
        color={"white"}
        onClick={() => {
          setEditingTicket(undefined);
          editTicketOnOpen();
        }}
      >
        Add Ticket
      </Button>
      {editTicketDrawer}
      {cancelTicketDrawer}
    </FloatingContainerClear>
  );
};

export default UpcomingTickets;
