import React, { PropsWithChildren, useCallback, useState } from "react";
import { User } from "../types/user";
export interface Course {
  id: number;
  name: string;
  holes: number;
}

type BcgContextType = {
  user?: User;
  setUser: (user?: User) => void;
  headers?: Record<string, string | number>;
  setHeaders: (headers?: Record<string, string | number>) => void;
  courses: Course[];
  setCourses: (courses: Course[]) => void;
};

const initialCtx: BcgContextType = {
  setUser: () => {},
  courses: [],
  setHeaders: () => {},
  setCourses: () => {},
};

export const BcgContext = React.createContext<BcgContextType>(initialCtx);

export const BcgProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [headers, setHeaders] = useState<Record<string, string | number>>();
  const [courses, setCourses] = useState<Course[]>([]);
  const [user, setUser] = useState<User>();

  const value: BcgContextType = {
    user: user,
    setUser: useCallback((user?: User) => {
      return setUser(user);
    }, []),
    courses: courses,
    setCourses: useCallback((courses: Course[]) => {
      return setCourses(courses);
    }, []),
    headers: headers,
    setHeaders: useCallback((headers?: Record<string, string | number>) => {
      return setHeaders(headers);
    }, []),
  };

  return <BcgContext.Provider value={value}>{children}</BcgContext.Provider>;
};

export function useBcg() {
  return React.useContext(BcgContext);
}
