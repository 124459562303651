import "./index.css";

import { Navigate, createBrowserRouter } from "react-router-dom";

import ErrorPage from "./errorPage";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Reservations from "./pages/Reservations";
import TeeTimes from "./pages/TeeTimes";
import Tickets from "./pages/Tickets";
import ProtectedRoute from "./pages/ProtectedRoute";
import BCGProtectedPage from "./pages/BCGProtectedPage";
import Logout from "./pages/Logout";
import Home from "./pages/Home";

const router = createBrowserRouter([
  {
    path: "/login",
    errorElement: <ErrorPage />,
    element: <Login />,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <BCGProtectedPage>
          <Home />
        </BCGProtectedPage>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/times",
    element: (
      <ProtectedRoute>
        <BCGProtectedPage>
          <TeeTimes />
        </BCGProtectedPage>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/tickets",
    element: (
      <ProtectedRoute>
        <BCGProtectedPage>
          <Tickets />
        </BCGProtectedPage>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/reservations",
    element: (
      <ProtectedRoute>
        <BCGProtectedPage>
          <Reservations />
        </BCGProtectedPage>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: (
      <ProtectedRoute>
        <BCGProtectedPage>
          <Profile />
        </BCGProtectedPage>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <Navigate to="/home" />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
