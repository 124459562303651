import { Flex } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC, useCallback, useEffect, useState } from "react";

import {
  ResponsePreviousReservation,
  ResponseUpcomingReservation,
} from "../types/bcgBackendResponses";
import { useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import LoadingModal from "../components/LoadingModal";
import useModal from "../util/useModal";
import ReservationTable from "../components/ReservationTable";
import logger from "../util/logger";

const Reservations: FC = () => {
  const { headers } = useBcg();
  const { isLoading, call, error } = useAxiosRequest();
  const {
    modal: loadingModal,
    onClose: onCloseLoadingModal,
    onOpen: onOpenLoadingModal,
  } = useModal(<LoadingModal />, "sm");
  const [reservations, setReservations] = useState<{
    myUpcomingReservations:
      | ResponseUpcomingReservation.UpcomingReservation[]
      | ResponsePreviousReservation.PreviousReservation[];

    myPreviousReservations:
      | ResponseUpcomingReservation.UpcomingReservation[]
      | ResponsePreviousReservation.PreviousReservation[];
  }>();
  const getReservations = useCallback(async () => {
    if (!headers) return;
    try {
      const res = await call<{
        myUpcomingReservations: ResponseUpcomingReservation.UpcomingReservation[];
        myPreviousReservations: ResponsePreviousReservation.PreviousReservation[];
      }>("/reservations", "POST", {
        headers,
      });
      if (res?.data) {
        const reservations = {
          myUpcomingReservations: res.data.myUpcomingReservations,
          myPreviousReservations: res.data.myPreviousReservations,
        };

        setReservations(reservations);
      }
    } catch (err) {
      console.error(err);
      logger.error("Error getting reservations", { error: err });
    }
  }, [call, headers]);
  useEffect(() => {
    if (isLoading) return;
    if (reservations) return;
    if (error) return;
    try {
      getReservations();
    } catch (err) {
      console.error(err);
      logger.error("Error getReservations use effect", { error: err });
    }
  }, [getReservations, isLoading, reservations, error]);

  const { myUpcomingReservations, myPreviousReservations } = reservations || {};
  useEffect(() => {
    if (!isLoading) {
      return onCloseLoadingModal();
    }
    return onOpenLoadingModal();
  }, [isLoading, onOpenLoadingModal, onCloseLoadingModal]);
  return (
    <Flex
      flexDirection={"column"}
      rowGap={5}
      alignItems={"center"}
      w="100%"
      p={3}
    >
      {loadingModal}

      {myUpcomingReservations?.length ? (
        <ReservationTable
          columns={[
            { label: "Tee Time", key: "startTime", type: "date" },
            { label: "Course", key: "courseName", type: "string" },
            {
              label: "Number of Players",
              key: "numberOfPlayer",
              type: "number",
            },
          ]}
          rows={myUpcomingReservations || []}
          tableHeading="Upcoming Reservations"
        />
      ) : null}
      {myPreviousReservations?.length ? (
        <ReservationTable
          columns={[
            { label: "Tee Time", key: "startTime", type: "date" },
            { label: "Course", key: "courseName", type: "string" },
            {
              label: "Number of Players",
              key: "numberOfPlayer",
              type: "number",
            },
          ]}
          rows={myPreviousReservations || []}
          tableHeading="Previous Reservations"
        />
      ) : null}
    </Flex>
  );
};

export default Reservations;

const groupByDateTime = (
  reservations:
    | ResponseUpcomingReservation.UpcomingReservation[]
    | ResponsePreviousReservation.PreviousReservation[]
) => {
  const groupedReservations: {
    [key: string]:
      | ResponseUpcomingReservation.UpcomingReservation[]
      | ResponsePreviousReservation.PreviousReservation[];
  } = {};
  reservations.forEach((reservation) => {
    const startTime = reservation.startTime;
    const key = moment(new Date(startTime)).format("MMM D YYYY");
    if (!groupedReservations[key]) {
      groupedReservations[key] = [];
    }
    groupedReservations[key].push(reservation);
  });
  return groupedReservations;
};
