import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC, useRef, useEffect } from "react";

interface Option {
  label: string;
  value: string | number;
}

interface CustomSelectProps {
  options: Option[];
  selectedValue?: string | number;
  onSelect: (value: string | number) => void;
  placeholder?: string;
  buttonBgColor?: string;
  buttonHoverBgColor?: string;
  optionSelectedBgColor?: string;
  optionHoverBgColor?: string;
  width?: string;
}

const CustomSelect: FC<CustomSelectProps> = ({
  options,
  selectedValue,
  onSelect,
  placeholder = "Select...",
  buttonBgColor = "transparent",
  buttonHoverBgColor = "glassBackground",
  // optionSelectedBgColor = 'glassBackground',
  optionHoverBgColor = "hoverGlassBackground",
  width = "fit-content",
}) => {
  const selectedLabel =
    options.find((option) => option.value === selectedValue)?.label ||
    placeholder;

  const popoverBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (popoverBodyRef.current && selectedValue !== undefined) {
      const selectedOption = popoverBodyRef.current.querySelector(
        `[data-value="${selectedValue}"]`
      );
      if (selectedOption) {
        selectedOption.scrollIntoView({ block: "center", inline: "center" });
      }
    }
  }, [selectedValue]);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              w={width}
              backgroundColor={buttonBgColor}
              color="white"
              border="1px solid rgba(255, 255, 255, 0.2)"
              _hover={{ backgroundColor: buttonHoverBgColor }}
            >
              {selectedLabel}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            backgroundColor={"glassBackground"}
            maxHeight="200px"
            overflowY="auto"
            minWidth="auto"
            width="fit-content"
            boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
            border="1px solid rgba(255, 255, 255, 0.2)"
            backdropFilter={"blur(25px)"}
          >
            <PopoverBody p="0" ref={popoverBodyRef}>
              <VStack spacing={0} align="start">
                {options.map((option) => (
                  <Flex
                    key={option.value}
                    data-value={option.value}
                    borderRadius={0}
                    w="full"
                    backgroundColor={"transparent"}
                    cursor={"pointer"}
                    _hover={{ backgroundColor: optionHoverBgColor }}
                    onClick={() => {
                      onSelect(option.value);
                      onClose();
                    }}
                    p="10px"
                    gap={2}
                    alignItems={"center"}
                  >
                    {selectedValue ? (
                      <CheckIcon
                        opacity={option.value === selectedValue ? 1 : 0}
                      />
                    ) : null}

                    <Text>{option.label}</Text>
                  </Flex>
                ))}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default CustomSelect;
