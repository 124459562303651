import { FC } from "react";
import {
  Box,
  Flex,
  Link,
  Spacer,
  Image,
  Heading,
  useMediaQuery,
  GenericAvatarIcon,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import GolferImage from "../assets/golfer_silhouette_white_pure.png";
import { CalendarIcon, TimeIcon } from "@chakra-ui/icons";
import TicketImage from "../assets/white_tickets_icon.png";
import { backdropFilter } from "../themes/Button";
const NavBar: FC = () => {
  const isMobileView = useMediaQuery("(max-width: 600px)")[0];
  const navigate = useNavigate();
  return (
    <Flex
      backgroundColor="glassBackground" // Adjust the alpha for transparency
      backdropFilter={backdropFilter}
      color="white"
      p="10px"
      alignItems={"center"}
    >
      <Flex as={RouterLink} to="/home" alignItems={"flex-end"}>
        <Image src={GolferImage} alt="Golfer" h="50px" mb={-1} />
        <Box>
          {isMobileView ? null : <Heading size="md"> Red Tees</Heading>}
        </Box>
      </Flex>

      <Spacer />
      <Flex alignItems={"center"} columnGap={4}>
        <Link as={RouterLink} to="/times" px="3">
          <Flex columnGap={"5px"} alignItems={"center"}>
            <TimeIcon />
            {isMobileView ? null : "Tee Times"}
          </Flex>
        </Link>
        <Link as={RouterLink} to="/tickets" px="3">
          <Flex columnGap={"5px"} alignItems={"center"}>
            <Image src={TicketImage} boxSize={"20px"} />
            {isMobileView ? null : "Tickets"}
          </Flex>
        </Link>
        <Link as={RouterLink} to="/reservations" px="3">
          <Flex columnGap={"5px"} alignItems={"center"}>
            <CalendarIcon />
            {isMobileView ? null : "Reservations"}
          </Flex>
        </Link>
        <Link as={RouterLink} to="/logout" px="3">
          <Flex columnGap={"5px"} alignItems={"center"}>
            <GenericAvatarIcon boxSize={"20px"} />
            {isMobileView ? null : "Logout"}
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};

export default NavBar;
