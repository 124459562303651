import { Button, Divider, Flex, Heading, Text } from "@chakra-ui/react";
import { FC, useState } from "react";

import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import { useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import { FloatingContainerClear } from "./FloatingContainer";
import FormNumberField from "./FormNumberField";

const TeeTimeCard: FC<{
  teeTime: ResponseAvailableTeeTimes.AvailableTeeTimes;
  courseIds: number[];
}> = ({ teeTime, courseIds }) => {
  const { headers } = useBcg();
  const { call, isLoading } = useAxiosRequest();
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(
    teeTime.availableParticipantNo.length
  );

  const handleSubmit = async () => {
    call("/book-tee-time", "POST", {
      headers,
      id: localStorage.getItem("tee_time_user_id"),
      teeTime,
      numberOfPlayers,
      courseIds,
    });
  };
  return (
    <FloatingContainerClear>
      <Flex flexDirection={"column"} w="100%" gap="2">
        <Flex flexDirection={"column"} w="100%" gap="2">
          <Heading>{teeTime.courseName}</Heading>
          <Text>
            {new Date(teeTime.startTime).toLocaleTimeString()} Tee Time
          </Text>
          <Text>{teeTime.availableParticipantNo.length} Available Slots</Text>
        </Flex>
        <Divider />
        <Flex
          justify="space-between"
          bg="glassBackground"
          py="4"
          pr="10px"
          borderRadius={10}
        >
          <Flex flexGrow={1} maxW={"300px"} pr="10px">
            <FormNumberField
              min={1}
              max={teeTime.availableParticipantNo.length}
              number={numberOfPlayers}
              handleNumberChange={(s: string, n: number) =>
                setNumberOfPlayers(n)
              }
            />
          </Flex>
          <Button
            size="sm"
            isLoading={isLoading}
            loadingText="Booking Tee Time"
            onClick={handleSubmit}
            isDisabled={isLoading}
            variant={"primary"}
            alignSelf={"center"}
          >
            Book {numberOfPlayers} Players
          </Button>
        </Flex>
      </Flex>
    </FloatingContainerClear>
  );
};

export default TeeTimeCard;
