import { FC } from "react";
import moment, { Moment } from "moment-timezone";
import { Flex, Grid, Text, Box } from "@chakra-ui/react";
import FormTimeRangeField, {
  FormTimeRangeFieldProps,
} from "./FormTimeRangeField";
import CustomSelect from "./CustomSelect";

const FormDatePicker: FC<{
  date: Moment;
  setDate: (date: Moment) => void;
  displayYear?: boolean;
  displayTimeRange?: boolean;
  timeRangeProps?: FormTimeRangeFieldProps;
  minDate?: Moment;
  maxDate?: Moment;
}> = ({
  date,
  setDate,
  displayYear = false,
  displayTimeRange = false,
  timeRangeProps,
  minDate,
  maxDate,
}) => {
  const startDay = date.clone().startOf("month").startOf("week");
  const endDay = date.clone().endOf("month").endOf("week");
  const daysArray = () => {
    const days: Moment[] = [];
    let day = startDay.clone();
    while (day.isBefore(endDay, "day")) {
      days.push(day.clone());
      day.add(1, "day");
    }
    return days;
  };

  const years = Array(20)
    .fill(0)
    .map((_, i) => moment().year() - 10 + i);

  const months = moment.months();
  return (
    <Box>
      <Flex justifyContent="start" m={4}>
        {displayYear && (
          <CustomSelect
            selectedValue={date.year()}
            onSelect={(value) =>
              setDate(date.clone().year(parseInt(value.toString())))
            }
            options={years.map((year) => ({
              label: year.toString(),
              value: year,
            }))}
          />
        )}
        <CustomSelect
          selectedValue={date.month()}
          onSelect={(value) => setDate(date.clone().month(value))}
          options={months.map((month, idx) => ({
            label: month,
            value: idx,
          }))}
        />
      </Flex>
      <Grid templateColumns="repeat(7, 1fr)" gap={1}>
        {moment.weekdaysShort().map((day) => (
          <Text key={day} textAlign="center">
            {day}
          </Text>
        ))}
        {daysArray().map((day) => {
          const isBeforeMinDate = (() => {
            if (minDate) {
              return (
                day.month() === minDate.month() && day.isBefore(minDate, "date")
              );
            }
            return false;
          })();
          const isAfterMaxDate = (() => {
            if (maxDate) {
              return (
                day.month() === maxDate.month() &&
                day.isSameOrAfter(maxDate, "date")
              );
            }
            return false;
          })();
          const isDisabled =
            day.month() !== date.month() || isBeforeMinDate || isAfterMaxDate;

          const isSelected = date.isSame(day, "date");
          return (
            <Flex key={day.format("DDMMYYYY")} justifyContent={"center"}>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                boxSize={"40px"}
                p={2}
                backgroundColor={
                  date.isSame(day, "day") ? "primaryRed" : "none"
                }
                borderRadius={"50%"}
                cursor={isDisabled ? "not-allowed" : "pointer"}
                onClick={isDisabled ? () => null : () => setDate(day)}
                _hover={{
                  backgroundColor: isDisabled
                    ? "none"
                    : isSelected
                    ? "activeRed"
                    : "hoverGlassBackground",
                }}
              >
                <Text
                  textAlign="center"
                  color={isDisabled ? "greyText" : "white"}
                >
                  {day.date()}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Grid>
      {displayTimeRange && timeRangeProps && (
        <Flex p="20px" pb="0px">
          <FormTimeRangeField {...timeRangeProps} displayLabel={false} />
        </Flex>
      )}
    </Box>
  );
};

export default FormDatePicker;
