import { Flex, useToast } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import TeeTimeSearchForm from "../components/TeeTimeSearchForm";
import { useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import TicketCard from "../components/TicketCard";
import { Ticket } from "../types/tickets";

export const onFormComplete = async (toast, getMyTickets) => {
  toast({
    title: "Ticket Created.",
    description: "Your ticket has been created.",
    status: "success",
    duration: 9000,
    isClosable: true,
  });
  await getMyTickets();
};

const Tickets: FC = () => {
  const { courses } = useBcg();
  const { isLoading, call } = useAxiosRequest();
  const [tickets, setTickets] = useState<Ticket[]>();
  const userId = localStorage.getItem("tee_time_user_id");
  const toast = useToast();
  const getMyTickets = useCallback(async () => {
    if (isLoading) return;
    const t = await call<any[]>(`/user/${userId}/tickets`, "GET", {});
    setTickets(t?.data);
  }, [call, isLoading, userId]);

  const onCancelComplete = async (toast) => {
    toast({
      title: "Ticket Cancelled.",
      description: "Your ticket has been cancelled.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    await getMyTickets();
  };

  useEffect(() => {
    if (tickets) return;
    getMyTickets();
  }, [getMyTickets, tickets]);

  return (
    <Flex flexDirection={"column"} w="100%" alignItems={"center"}>
      <TeeTimeSearchForm
        url="/ticket"
        onFormComplete={() => {
          onFormComplete(toast, getMyTickets);
        }}
        hasMaxDate={false}
        title="Create Ticket"
      />
      <Flex flexDirection={"column"} gap={4} w="100%">
        {courses &&
          tickets?.map((t, i) => {
            return (
              <TicketCard
                key={t.id}
                ticket={t}
                courses={courses}
                onCancelComplete={() => onCancelComplete(toast)}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};

export default Tickets;
