import { defineStyle } from "@chakra-ui/react";

const primary = defineStyle({
  table: {
    p: "10",
  },
  thead: {},
  tfoot: {},
  tbody: {
    backgroundColor: "glassBackground",
  },
  tr: {
    backgroundColor: "glassBackground",
  },
  th: { p: 2, textAlign: "center" },

  td: {
    borderBottom: "1px solid",
    borderColor: "glassBackground",
    p: 1,
    fontSize: "sm",
    textAlign: "center",
  },
});

export const Table = {
  variants: {
    primary,
  },
};
