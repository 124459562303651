import { Button, Flex, FormControl } from "@chakra-ui/react";
import { FC } from "react";
import { gradientBackground, gradientRedButtonHover } from "../themes/Button";
import { hoverGlassBackground } from "../themes/theme";

const FormNumberField: FC<{
  min: number;
  max: number;
  number: number;
  handleNumberChange: (valueAsString: string, valueAsNumber: number) => void;
}> = ({ min, max, number, handleNumberChange }) => {
  const availableNumbers = Array.from(
    { length: max - min + 1 },
    (_, i) => i + min
  );
  return (
    <FormControl px={4}>
      <Flex align="center" justifyContent={"space-between"} gap="5px">
        {availableNumbers.map((num, index) => {
          return (
            <Flex
              key={index}
              boxSize={"40px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={10}
              onClick={() => handleNumberChange(num.toString(), num)}
              backgroundColor={
                number === num ? "primaryRed" : "hoverGlassBackground"
              }
              _hover={{
                backgroundColor:
                  number === num ? "activeRed" : "rgba(20, 16, 16, 0.4)",
              }}
            >
              {num}
            </Flex>
          );
        })}
      </Flex>
    </FormControl>
  );
};

export const FormNumberField2: FC<{
  min: number;
  max: number;
  number: number;
  handleNumberChange: (valueAsString: string, valueAsNumber: number) => void;
}> = ({ min, max, number, handleNumberChange }) => {
  const availableNumbers = Array.from(
    { length: max - min + 1 },
    (_, i) => i + min
  );
  return (
    <FormControl px={4}>
      <Flex align="center" gap="5px">
        {availableNumbers.map((num, index) => {
          return (
            <Flex
              key={index}
              boxSize={"40px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={10}
              onClick={() => handleNumberChange(num.toString(), num)}
              backgroundImage={
                number === num ? gradientBackground : hoverGlassBackground
              }
              _hover={{
                backgroundColor:
                  number === num
                    ? gradientRedButtonHover
                    : "rgba(20, 16, 16, 0.4)",
              }}
            >
              {num}
            </Flex>
          );
        })}
      </Flex>
    </FormControl>
  );
};

export default FormNumberField;
