import { defineStyle } from "@chakra-ui/react";
export const backdropFilter = "blur(12px)";
export const gradientBackground =
  "linear-gradient(304deg, rgb(88 0 0) 40%, #120101 92%)";

export const gradientRedButtonHover =
  "linear-gradient(304deg, #a10d0d 40%, #2c0202 92%)";
const gradientOrangeButtonHover =
  "linear-gradient(304deg, #b23a00 40%, #430202 92%)";
const floating = defineStyle({
  backgroundColor: "glassBackground", // Adjust the alpha for transparency
  backdropFilter: "blur(12px)",
  _hover: {
    backgroundColor: "hoverGlassBackground",
  },
});

const primary = defineStyle({
  backgroundColor: "primaryRed", // Adjust the alpha for transparency
  backdropFilter: "blur(12px)",
  _hover: {
    backgroundColor: "activeRed",
  },
});

const alternate = defineStyle({
  backgroundColor: "blue.400", // Adjust the alpha for transparency
  backdropFilter: "blur(12px)",
  _hover: {
    backgroundColor: "blue.500",
  },
});

const gradient = defineStyle({
  backgroundImage: gradientBackground,
  backdropFilter: "blur(12px)",

  _hover: {
    backgroundImage: gradientRedButtonHover,
  },
});

export const Button = {
  baseStyle: {
    width: "fit-content",
  },
  variants: {
    floating,
    primary,
    alternate,
    gradient,
  },
};
