import { Button, Flex, Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC } from "react";

import { Ticket } from "../types/tickets";
import { Course } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import { formatTime } from "./TeeTimeSearchForm";
import logger from "../util/logger";

const CancelTicketModal: FC<{
  onCancelComplete: () => void;
  courses: Course[];
  ticket?: Ticket;
}> = ({ ticket, onCancelComplete, courses }) => {
  const { call, isLoading } = useAxiosRequest();

  const onCancel = async () => {
    try {
      const res = await call(`/tickets/${ticket?.id}/cancel`, "POST", {});
      if (res?.status === 200) {
        onCancelComplete();
      }
    } catch (err) {
      console.error(err);
      logger.error("Error cancelling ticket", { error: err });
    }
  };
  if (!ticket) return null;
  return (
    <Flex p={7} flexDirection={"column"} w="100%" gap="5">
      <Flex flexDirection={"column"} w="100%" gap="2">
        <Text size={"sm"}>
          Course:{" "}
          {ticket.courseIds
            .map(
              (id) =>
                courses.find((course) => course.id === id)?.name ||
                "Unknown Course"
            )
            .join(", ")}
        </Text>
        <Text size={"sm"}>
          Date: {moment(ticket.searchDate).format("MMMM Do")}
        </Text>
        <Text size={"sm"}>
          Time Range: {formatTime(ticket.teeOffTimeMin)} -{" "}
          {formatTime(ticket.teeOffTimeMax)}
        </Text>
      </Flex>
      <Button
        mt="50px"
        size="lg"
        isLoading={isLoading}
        loadingText="Cancelling Ticket ..."
        onClick={onCancel}
        isDisabled={isLoading}
        variant={"gradient"}
        alignSelf={"center"}
      >
        Cancel Ticket
      </Button>
    </Flex>
  );
};

export default CancelTicketModal;
