export const Text = {
  baseStyle: {
    fontWeight: "500",
  },
  sizes: {
    "2xs": {
      fontSize: "10px",
    },
    xs: {
      fontSize: "12px",
    },
    sm: {
      fontSize: "14px",
    },
    md: {
      fontSize: "16px",
    },
    lg: {
      fontSize: "20px",
    },
    xl: {
      fontSize: "24px",
    },
  },
  variants: {
    primary: {
      fontFamily: "primaryFont",
    },
    secondary: {
      fontFamily: "secondaryFont",
    },
  },
  defaultProps: {},
};
