import {
  Flex,
  Image,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

import GolferImage from "../assets/golfer_silhouette_transparent_background.png";
import LoadingDots from "./LoadingDots";
import { backdropFilter } from "../themes/Button";

const LoadingModal: FC = () => {
  return (
    <>
      <ModalOverlay />
      <ModalContent bgColor={"greyBackground"} backdropFilter={backdropFilter}>
        <ModalBody>
          <Flex
            justifyContent={"baseline"}
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Image src={GolferImage} alt="Golfer" h="200px" />
            <LoadingDots />
          </Flex>
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default LoadingModal;
