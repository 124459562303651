import { FC } from "react";
import { Flex, Checkbox } from "@chakra-ui/react";

export interface MultiSelectOption {
  label: string;
  value: string;
}
const FormMultiSelect: FC<{
  options: MultiSelectOption[];
  selectedOptions: MultiSelectOption[];
  handleAccordionMultiSelectChange: (value: string) => void;
}> = ({ options, selectedOptions, handleAccordionMultiSelectChange }) => {
  return options.map((option, i) => {
    return (
      <Flex
        key={i}
        flexDirection={"column"}
        onClick={() => handleAccordionMultiSelectChange(option.value)}
      >
        <Flex
          justifyContent={"space-between"}
          borderRadius={10}
          _hover={{
            backgroundColor: "hoverGlassBackground",
          }}
          p="2"
          gap={2}
        >
          {option.label}
          <Checkbox
            variant={"primary"}
            isChecked={
              !!selectedOptions.find((so) => so.value === option.value)
            }
          />
        </Flex>
      </Flex>
    );
  });
};

export default FormMultiSelect;
