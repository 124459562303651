import { defineStyle } from "@chakra-ui/react";

const primary = defineStyle({
  filledTrack: { color: "primaryRed", backgroundColor: "primaryRed" },
});

export const Slider = {
  variants: {
    primary,
  },
};
