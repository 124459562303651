import React, { PropsWithChildren, useCallback } from "react";

type AppContextType = {
  blackAndWhite: boolean;
  setBlackAndWhite: (blackAndWhite: boolean) => void;
};

const initialCtx: AppContextType = {
  blackAndWhite: false,
  setBlackAndWhite: () => {},
};

export const AppContext = React.createContext<AppContextType>(initialCtx);

export const AppContextProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [blackAndWhite, setBlackAndWhite] = React.useState<boolean>(false);
  const value: AppContextType = {
    blackAndWhite: blackAndWhite,
    setBlackAndWhite: useCallback((blackAndWhite: boolean) => {
      return setBlackAndWhite(blackAndWhite);
    }, []),
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  return React.useContext(AppContext);
}
