import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC, Fragment } from "react";
import { useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import logger from "../util/logger";

const ReservationTable: FC<{
  tableHeading: string;
  columns: { label: string; key: string; type: string }[];
  rows: any[];
}> = ({ tableHeading, columns, rows }) => {
  const { headers } = useBcg();
  const { isLoading, call } = useAxiosRequest();
  const cancelReservation = async (idx) => {
    if (!headers) return;
    if (!rows) return;
    const reservation = rows[idx];
    try {
      const res = await call("/cancel-reservation", "POST", {
        headers,
        upcomingReservation: reservation,
      });
      if (res?.data) {
        rows.splice(idx, 1);
      }
    } catch (err) {
      console.error(err);
      logger.error("Error cancelling reservation", { error: err });
    }
  };

  return (
    <TableContainer w="100%" borderRadius={10}>
      <Table variant={"primary"}>
        <Thead>
          <Tr alignSelf={"center"}>
            <Th></Th>
            <Th
            //colSpan={columns.length}
            // textAlign={"center"}
            >
              {tableHeading}
            </Th>
            <Th></Th>
          </Tr>

          <Tr>
            <Th>Tee Time</Th>
            <Th>Course</Th>
            <Th isNumeric>Players</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((r, idx) => {
            return (
              <Fragment key={idx}>
                <Tr key={idx}>
                  {columns.map((c, i) => {
                    if (c.type === "date") {
                      return (
                        <Td key={i}>
                          <Flex flexDirection={"column"}>
                            <Text>
                              {moment(r[c.key]).format("MMM DD YYYY")}
                            </Text>
                            <Text>{moment(r[c.key]).format("hh:mm A")}</Text>
                          </Flex>
                        </Td>
                      );
                    }
                    if (c.type === "number") {
                      return (
                        <Td key={i}>
                          <Text>{r[c.key]} Player(s)</Text>
                        </Td>
                      );
                    }
                    return (
                      <Td key={i}>
                        <Text>{r[c.key]}</Text>
                      </Td>
                    );
                  })}
                </Tr>
                {!!r["ownerGolferId"] ? (
                  <Tr alignSelf={"center"}>
                    <Th colSpan={columns.length} textAlign={"center"}>
                      <Button
                        isLoading={isLoading}
                        variant={"primary"}
                        onClick={() => cancelReservation(idx)}
                        w="100%"
                      >
                        Cancel
                      </Button>
                    </Th>
                  </Tr>
                ) : null}
              </Fragment>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReservationTable;
