import { Flex, FlexProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";
import { backdropFilter } from "../themes/Button";

export const FloatingContainerClear: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      backgroundColor="glassBackground" // Adjust the alpha for transparency
      w={"100%"}
      flexDirection={"column"}
      borderRadius={"15px"}
      alignItems={"flex-start"}
      p="15px"
      py="25px"
      gap="4"
      backdropFilter={backdropFilter}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const FloatingContainerGrey: FC<PropsWithChildren<FlexProps>> = ({
  children,
  ...rest
}) => {
  return (
    <Flex
      backgroundColor="greyBackground" // Adjust the alpha for transparency
      borderRadius="20px"
      backdropFilter={backdropFilter}
      {...rest}
    >
      {children}
    </Flex>
  );
};
