import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { gradientBackground } from "../themes/Button";

const useDrawer = (children, title: string, onCloseComplete: () => void) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobileView = useMediaQuery("(max-width: 600px)")[0];

  const drawer = (
    <Drawer
      placement={"bottom"}
      onClose={() => {
        onClose();
        onCloseComplete();
      }}
      isOpen={isOpen}
      size={"xl"}
    >
      <DrawerOverlay />
      <DrawerContent
        background={gradientBackground}
        height={isMobileView ? "85%" : "95%"}
      >
        <Flex
          flexDirection={"column"}
          w="100%"
          backgroundColor={"glassBackground"}
          height={"100%"}
        >
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>
          <DrawerBody p="0">{children}</DrawerBody>
        </Flex>
      </DrawerContent>
    </Drawer>
  );

  return { drawer, onOpen, onClose };
};

export default useDrawer;
