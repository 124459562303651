import { Flex } from "@chakra-ui/react";
import { FC } from "react";

import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import TeeTimeCard from "./TeeTimeCard";

const AvailableTeeTimes: FC<{
  teeTimes?: ResponseAvailableTeeTimes.AvailableTeeTimes[];
  courseIds: number[];
}> = ({ teeTimes, courseIds }) => {
  return (
    <Flex flexDirection={"column"} w="100%" gap="10px">
      {teeTimes?.map((teeTime, i) => {
        return <TeeTimeCard key={i} teeTime={teeTime} courseIds={courseIds} />;
      })}
    </Flex>
  );
};

export default AvailableTeeTimes;
