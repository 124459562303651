import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FC } from "react";

export interface Column {
  label: string;
  key: string;
  type: string;
  iconProps?: {
    actions: {
      icon: string;
      onClick: (row: any) => void;
    }[];
  };
}

const getIcon = (icon: string, onClick) => {
  switch (icon) {
    case "edit":
      return <EditIcon key={icon} onClick={onClick} />;
    case "delete":
      return <DeleteIcon key={icon} onClick={onClick} />;
    case "add":
      return <AddIcon key={icon} onClick={onClick} />;
    default:
      return null;
  }
};
const BasicTable: FC<{
  variant: "primary" | "simple";
  columns: Column[];
  rows?: any[];
  dataLoading: boolean;
  noRowsMessage?: string;
}> = ({ variant, columns, rows, dataLoading, noRowsMessage }) => {
  if (dataLoading || !rows) {
    return (
      <Flex alignSelf={"center"}>
        <Spinner />
      </Flex>
    );
  }

  if (!dataLoading && rows.length === 0) {
    return (
      <Flex alignSelf={"center"}>
        <Text fontWeight={"400"}>{noRowsMessage}</Text>
      </Flex>
    );
  }

  return (
    <TableContainer w="100%" borderRadius={10} maxH="400px" overflowY="scroll">
      <Table variant={variant}>
        <Thead>
          <Tr>
            {columns.map((c, i) => (
              <Th
                key={i}
                color={"white"}
                fontSize={"12px"}
                p="1"
                textAlign={"center"}
              >
                {c.label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((r, idx) => {
            return (
              <Tr key={idx}>
                {columns.map((c, i) => {
                  if (c.iconProps) {
                    return (
                      <Td
                        key={r.id}
                        fontSize={"12px"}
                        textAlign={"center"}
                        p={variant === "simple" ? "2" : undefined}
                      >
                        <Flex gap={2} w="100%" justifyContent={"space-around"}>
                          {c.iconProps.actions.map((a) => {
                            const icon = getIcon(a.icon, () => a.onClick(r));
                            return icon;
                          })}
                        </Flex>
                      </Td>
                    );
                  }
                  return (
                    <Td
                      key={i}
                      fontSize={"12px"}
                      textAlign={"center"}
                      p={variant === "simple" ? "2" : undefined}
                    >
                      <Text>{r[c.key]}</Text>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
