import {
  Button,
  Flex,
  Heading,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { FC, useMemo, useState } from "react";

import useAxiosRequest from "../util/useAxiosRequest";
import { useBcg } from "../util/BcgProvider";
import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import FormDatePicker from "./FormDatePicker";
import moment from "moment-timezone";
import FormNumberField from "./FormNumberField";
import AccordionSelect from "./AccordionSelect";
import FormMultiSelect, { MultiSelectOption } from "./FormMultiSelect";
import { Ticket } from "../types/tickets";
import logger from "../util/logger";

export const formatTime = (hour: number) => {
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12;
  return `${formattedHour}:00 ${period}`;
};

const TeeTimeSearchForm: React.FC<{
  url: string;
  onFormComplete: (
    teeTimes: ResponseAvailableTeeTimes.AvailableTeeTimes[]
  ) => void;
  hasMaxDate: boolean;
  title: string;
  ticket?: Ticket;
}> = ({ url, onFormComplete, hasMaxDate, title, ticket }) => {
  const isMobileView = useMediaQuery("(max-width: 600px)")[0];

  const { call, isLoading } = useAxiosRequest();
  const [startDate, setStartDate] = useState(
    ticket?.searchDate ? moment(ticket?.searchDate) : moment()
  );
  const { headers, courses } = useBcg();
  const options = useMemo(() => {
    return courses
      .map((c) => ({ label: c.name, value: `${c.id}` }))
      .sort((a, b) => {
        // if includes 18 it should go first
        if (a.label.includes("18")) return -1;
        return 1;
      });
  }, [courses]);
  const [timeRange, setTimeRange] = useState<[number, number]>([
    ticket?.teeOffTimeMin ?? 5,
    ticket?.teeOffTimeMax ?? 21,
  ]);
  const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>(
    ticket?.courseIds
      ? options.filter((option) =>
          ticket.courseIds.includes(parseInt(option.value))
        )
      : []
  );
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(
    ticket?.numberOfPlayers ?? 4
  );
  const toast = useToast();

  const handleTimeRangeChange = (values: [number, number]) =>
    setTimeRange(values);

  // const handleSelectedOptionsChange = (values: string | string[]) => {
  //   const newSelectedOptions = options.filter((option) =>
  //     values.includes(option.value)
  //   );
  //   return setSelectedOptions(newSelectedOptions);
  // };
  const handleAccordionMultiSelectChange = (value: string) => {
    const filteredSelectedOptions = selectedOptions.filter(
      (option) => value !== option.value
    );
    if (filteredSelectedOptions.length === selectedOptions.length)
      return setSelectedOptions([
        ...selectedOptions,
        ...options.filter((option) => value === option.value),
      ]);

    return setSelectedOptions(filteredSelectedOptions);
  };

  const handleNumberOfPlayersChange = (
    valueAsString: string,
    valueAsNumber: number
  ) => setNumberOfPlayers(valueAsNumber);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const resp = await call<any[]>(url, "POST", {
        headers,
        id: localStorage.getItem("tee_time_user_id"),
        startDate,
        teeOffTimeMin: timeRange[0],
        teeOffTimeMax: timeRange[1],
        numberOfPlayers,
        courseIds: selectedOptions.map((option) => parseInt(option.value)),
      });
      if (resp) {
        onFormComplete(resp.data);
      }
    } catch (err) {
      console.error(err);
      logger.error(`Failed to submit search form for route ${url}`, {
        error: err,
      });
      toast({
        title: "Form failed to submit.",
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formFieldsProps = {
    startDate,
    setStartDate,
    timeRange,
    setTimeRange,
    hasMaxDate,
    selectedOptions,
    setSelectedOptions,
    numberOfPlayers,
    setNumberOfPlayers,
    options,
    handleAccordionMultiSelectChange,
    handleTimeRangeChange,
    handleNumberOfPlayersChange,
    isLoading,
  };

  return (
    <Flex as="form" onSubmit={handleSubmit} p={2} w="100%">
      <Flex flexDirection={"column"} rowGap={4} w="100%">
        <Heading size="lg">{title}</Heading>
        {isMobileView ? (
          <FormFieldsColumns {...formFieldsProps} />
        ) : (
          <FormFieldsRows {...formFieldsProps} />
        )}
      </Flex>
    </Flex>
  );
};

export default TeeTimeSearchForm;

interface FormFieldsProps {
  startDate: moment.Moment;
  setStartDate: (date: moment.Moment) => void;
  timeRange: [number, number];
  setTimeRange: (range: [number, number]) => void;
  hasMaxDate: boolean;
  selectedOptions: MultiSelectOption[];
  setSelectedOptions: (options: MultiSelectOption[]) => void;
  numberOfPlayers: number;
  setNumberOfPlayers: (players: number) => void;
  options: MultiSelectOption[];
  handleAccordionMultiSelectChange: (value: string) => void;
  handleTimeRangeChange: (values: [number, number]) => void;
  handleNumberOfPlayersChange: (
    valueAsString: string,
    valueAsNumber: number
  ) => void;
  isLoading: boolean;
}

const FormFieldsColumns: FC<FormFieldsProps> = ({
  startDate,
  setStartDate,
  timeRange,
  hasMaxDate,
  selectedOptions,
  numberOfPlayers,
  options,
  handleAccordionMultiSelectChange,
  handleTimeRangeChange,
  handleNumberOfPlayersChange,
  isLoading,
}) => {
  return (
    <Flex flexDirection={"column"} gap={"4"} width={"100%"}>
      <AccordionSelect
        buttonTitle={
          <Flex w="100%" justifyContent={"space-between"}>
            <Heading size="sm">
              {moment(startDate).format("MM/DD/YYYY")}
            </Heading>
            <Heading size="sm">
              {formatTime(timeRange[0])} - {formatTime(timeRange[1])}
            </Heading>
          </Flex>
        }
      >
        <FormDatePicker
          date={startDate}
          setDate={setStartDate}
          displayTimeRange={true}
          timeRangeProps={{ timeRange, formatTime, handleTimeRangeChange }}
          minDate={moment()}
          maxDate={hasMaxDate ? moment().add(8, "days") : undefined}
        />
      </AccordionSelect>

      <AccordionSelect
        buttonTitle={
          <Heading size="sm">
            {selectedOptions.length > 0 ? selectedOptions.length + " " : ""}
            Select Courses
          </Heading>
        }
      >
        <FormMultiSelect
          options={options}
          selectedOptions={selectedOptions}
          handleAccordionMultiSelectChange={handleAccordionMultiSelectChange}
        />
      </AccordionSelect>

      <AccordionSelect
        buttonTitle={<Heading size="sm">{numberOfPlayers} Players</Heading>}
      >
        <FormNumberField
          min={1}
          max={4}
          number={numberOfPlayers}
          handleNumberChange={handleNumberOfPlayersChange}
        />
      </AccordionSelect>
      <Button
        variant={"primary"}
        type="submit"
        isLoading={isLoading}
        loadingText="Submitting"
        isDisabled={isLoading}
        alignSelf={"flex-end"}
      >
        Submit
      </Button>
    </Flex>
  );
};

const FormFieldsRows: FC<FormFieldsProps> = ({
  startDate,
  setStartDate,
  timeRange,
  hasMaxDate,
  selectedOptions,
  numberOfPlayers,
  options,
  handleAccordionMultiSelectChange,
  handleTimeRangeChange,
  handleNumberOfPlayersChange,
  isLoading,
}) => {
  return (
    <Flex flexDirection={"column"} gap={"4"} width={"100%"}>
      <Flex flexDirection={"row"} gap={"4"} width={"100%"}>
        <Flex flexDirection={"column"} gap={"4"}>
          <AccordionSelect
            isOpenDefault={true}
            buttonTitle={
              <Flex w="100%" justifyContent={"space-between"}>
                <Heading size="sm">
                  {moment(startDate).format("MM/DD/YYYY")}
                </Heading>
                <Heading size="sm">
                  {formatTime(timeRange[0])} - {formatTime(timeRange[1])}
                </Heading>
              </Flex>
            }
          >
            <FormDatePicker
              date={startDate}
              setDate={setStartDate}
              displayTimeRange={true}
              timeRangeProps={{ timeRange, formatTime, handleTimeRangeChange }}
              minDate={moment()}
              maxDate={hasMaxDate ? moment().add(8, "days") : undefined}
            />
          </AccordionSelect>
          <AccordionSelect
            isOpenDefault={true}
            buttonTitle={<Heading size="sm">{numberOfPlayers} Players</Heading>}
          >
            <FormNumberField
              min={1}
              max={4}
              number={numberOfPlayers}
              handleNumberChange={handleNumberOfPlayersChange}
            />
          </AccordionSelect>
        </Flex>

        <AccordionSelect
          isOpenDefault={true}
          buttonTitle={
            <Heading size="sm">
              {selectedOptions.length > 0 ? selectedOptions.length + " " : ""}
              Select Courses
            </Heading>
          }
          width="100%"
        >
          <FormMultiSelect
            options={options}
            selectedOptions={selectedOptions}
            handleAccordionMultiSelectChange={handleAccordionMultiSelectChange}
          />
        </AccordionSelect>
      </Flex>
      <Button
        variant={"primary"}
        type="submit"
        isLoading={isLoading}
        loadingText="Submitting"
        isDisabled={isLoading}
        alignSelf={"center"}
        width={"50%"}
      >
        Submit
      </Button>
    </Flex>
  );
};
