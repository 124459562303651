import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { FC, useState } from "react";

import { ResponseAvailableTeeTimes } from "../types/bcgBackendResponses";
import { useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import { FormNumberField2 } from "./FormNumberField";
import moment from "moment-timezone";

const BookTeeTimeModal: FC<{
  courseIds: number[];
  onBookComplete: () => void;
  teeTime?: ResponseAvailableTeeTimes.AvailableTeeTimes;
}> = ({
  courseIds,
  teeTime = {
    courseName: "",
    startTime: "",
    availableParticipantNo: [],
  },
}) => {
  const { headers } = useBcg();
  const { call, isLoading } = useAxiosRequest();

  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(
    teeTime.availableParticipantNo.length
  );

  const handleSubmit = async () => {
    call("/book-tee-time", "POST", {
      headers,
      id: localStorage.getItem("tee_time_user_id"),
      teeTime,
      numberOfPlayers,
      courseIds,
    });
  };
  return (
    <Flex p={7} flexDirection={"column"} w="100%" gap="5">
      <Flex flexDirection={"column"} w="100%" gap="2">
        <Heading size={"md"}>Please Confirm Tee Time Details</Heading>
        <Text size={"sm"}>Course: {teeTime.courseName}</Text>
        <Text size={"sm"}>
          Time: {moment(teeTime.startTime).format("h:mm A")}
        </Text>
      </Flex>

      <Flex gap={2} maxW={"fit-content"} flexDirection={"column"} pt="10px">
        <Heading size={"sm"}>Select Number Of Players</Heading>
        <FormNumberField2
          min={1}
          max={teeTime.availableParticipantNo.length}
          number={numberOfPlayers}
          handleNumberChange={(s: string, n: number) => setNumberOfPlayers(n)}
        />
      </Flex>

      <Button
        mt="50px"
        size="lg"
        isLoading={isLoading}
        loadingText="Booking Tee Time ..."
        onClick={handleSubmit}
        isDisabled={isLoading}
        variant={"gradient"}
        alignSelf={"center"}
      >
        Book {numberOfPlayers} Player{numberOfPlayers > 1 ? "s" : ""}
      </Button>
    </Flex>
  );
};

export default BookTeeTimeModal;
