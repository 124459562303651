import { Button, Flex, Text } from "@chakra-ui/react";
import moment from "moment-timezone";
import { FC } from "react";

import { Course, useBcg } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import { ResponseUpcomingReservation } from "../types/bcgBackendResponses";
import logger from "../util/logger";

const CancelReservationModal: FC<{
  onCancelComplete: () => void;
  courses: Course[];
  reservation?: ResponseUpcomingReservation.UpcomingReservation;
}> = ({ reservation, onCancelComplete, courses }) => {
  const { call, isLoading } = useAxiosRequest();
  const { headers } = useBcg();

  const onCancel = async () => {
    if (!headers) return;
    try {
      const res = await call("/cancel-reservation", "POST", {
        headers,
        upcomingReservation: reservation,
      });

      onCancelComplete();
    } catch (err) {
      console.error(err);
      logger.error("Error cancelling reservation", { error: err });
    }
  };
  if (!reservation) return null;
  return (
    <Flex p={7} flexDirection={"column"} w="100%" gap="5">
      <Flex flexDirection={"column"} w="100%" gap="2">
        <Text size={"sm"}>Course: {reservation.courseName}</Text>
        <Text size={"sm"}>
          Tee Time: {moment(reservation.startTime).format("MM/DD hh:mm a")}{" "}
        </Text>
        <Text size={"sm"}>Number of Players: {reservation.numberOfPlayer}</Text>
      </Flex>
      <Button
        mt="50px"
        size="lg"
        isLoading={isLoading}
        loadingText="Cancelling Reservation ..."
        onClick={onCancel}
        isDisabled={isLoading}
        variant={"gradient"}
        alignSelf={"center"}
      >
        Cancel Reservation
      </Button>
    </Flex>
  );
};

export default CancelReservationModal;
