import React from "react";
import { Flex, Text, Button, Badge, Grid } from "@chakra-ui/react";
import { Ticket } from "../types/tickets";
import { Course } from "../util/BcgProvider";
import useAxiosRequest from "../util/useAxiosRequest";
import AccordionSelect from "./AccordionSelect";
import moment from "moment-timezone";
import { FloatingContainerClear } from "./FloatingContainer";
import logger from "../util/logger";

interface TicketComponentProps {
  ticket: Ticket;
  courses: Course[];
  onCancelComplete: () => void;
}

const TicketCard: React.FC<TicketComponentProps> = ({
  ticket,
  courses,
  onCancelComplete,
}) => {
  const { isLoading, call } = useAxiosRequest();

  const courseLabels = ticket.courseIds.map(
    (id) => courses.find((course) => course.id === id)?.name || "Unknown Course"
  );

  const formatTimeRange = (min: number, max: number) => {
    const formatTime = (hour: number) => {
      const hourString =
        hour % 12 === 0
          ? "12"
          : hour % 12 < 10
          ? `0${hour % 12}`
          : `${hour % 12}`;
      const amPm = hour < 12 ? "AM" : "PM";
      return `${hourString}:00 ${amPm}`;
    };
    return `${formatTime(min)} - ${formatTime(max)}`;
  };

  const onCancel = async (id: string) => {
    try {
      const res = await call(`/tickets/${id}/cancel`, "POST", {});
      if (res?.status === 200) {
        onCancelComplete();
      }
    } catch (err) {
      console.error(err);
      logger.error("Error onCancel", { error: err });
    }
  };

  return (
    <FloatingContainerClear overflow="hidden" position={"relative"}>
      <Flex
        rowGap={3}
        flexDirection={"column"}
        w="100%"
        justifyContent={"space-between"}
      >
        <Flex columnGap={2} justifyContent={"space-between"}>
          <Text>
            {moment(ticket.searchDate).format("MMM DD")} at{" "}
            {formatTimeRange(ticket.teeOffTimeMin, ticket.teeOffTimeMax)}
          </Text>
          <Text textAlign={"center"}>{ticket.numberOfPlayers} Players</Text>
        </Flex>

        <AccordionSelect
          buttonTitle={<Text>{courseLabels.length} Courses</Text>}
        >
          <Grid templateColumns="repeat(2, 1fr)">
            {courseLabels.map((label, i) => (
              <Text key={i}>{label}</Text>
            ))}
          </Grid>
        </AccordionSelect>
        <Badge
          position={"absolute"}
          top={"3px"}
          right={"-27px"}
          transform="rotate(44deg)" // Apply rotation using the transform prop
          height={"30px"}
          alignSelf={"flex-end"}
          padding={2}
          pl="15px"
          fontSize={"12px"}
          borderRadius={10}
          minW="88px"
          w="min-content"
          colorScheme={
            ticket.isBooked
              ? "green"
              : ticket.isCancelled
              ? "yellow"
              : ticket.couldNotBook
              ? "red"
              : "gray"
          }
        >
          {ticket.isBooked
            ? "Booked"
            : ticket.isCancelled
            ? "Cancelled"
            : ticket.couldNotBook
            ? "Could Not Book"
            : "Pending"}
        </Badge>
        {!ticket.isBooked && !ticket.isCancelled && !ticket.couldNotBook && (
          <Button
            alignSelf={"flex-end"}
            variant={"primary"}
            onClick={() => onCancel(ticket.id)}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
        )}
      </Flex>
    </FloatingContainerClear>
  );
};

export default TicketCard;
