import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { FC, useState } from "react";

const FormInputField: FC<{
  label: string;
  inputType: string;
  helperText?: string;
  name: string;
  isRequired?: boolean;
}> = ({ label, inputType, helperText, name, isRequired }) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => setInput(e.target.value);
  const isError = input === "" && isRequired;

  return (
    <FormControl isRequired={isRequired} isInvalid={isError}>
      <Text>{label}</Text>
      <Input type={inputType} onChange={handleInputChange} name={name} />
      {isError ? (
        <FormErrorMessage>{label} is required</FormErrorMessage>
      ) : (
        helperText && (
          <FormHelperText color="white">
            <Text>{helperText}</Text>
          </FormHelperText>
        )
      )}
    </FormControl>
  );
};

export default FormInputField;
