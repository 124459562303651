import {
  FormControl,
  FormLabel,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
} from "@chakra-ui/react";
import { FC } from "react";

export interface FormTimeRangeFieldProps {
  timeRange: number[];
  handleTimeRangeChange: (values: [number, number]) => void;
  formatTime: (hour: number) => string;
  displayLabel?: boolean;
}

const FormTimeRangeField: FC<FormTimeRangeFieldProps> = ({
  timeRange,
  handleTimeRangeChange,
  formatTime,
  displayLabel = true,
}) => (
  <FormControl>
    {displayLabel && (
      <FormLabel htmlFor="timeRange">
        Time Range: {formatTime(timeRange[0])} - {formatTime(timeRange[1])}
      </FormLabel>
    )}

    <RangeSlider
      id="timeRange"
      name="timeRange"
      min={5}
      max={21}
      step={1}
      defaultValue={timeRange}
      onChangeEnd={handleTimeRangeChange}
      variant={"primary"}
    >
      <RangeSliderTrack>
        <RangeSliderFilledTrack />
      </RangeSliderTrack>
      <RangeSliderMark
        value={timeRange[0]}
        mt="1"
        ml="-2.5"
        fontSize="sm"
        w="max-content"
      >
        {formatTime(timeRange[0])}
      </RangeSliderMark>
      <RangeSliderMark
        value={timeRange[1]}
        mt="1"
        ml="-40.5"
        fontSize="sm"
        w="max-content"
      >
        {formatTime(timeRange[1])}
      </RangeSliderMark>

      <RangeSliderThumb index={0} />
      <RangeSliderThumb index={1} />
    </RangeSlider>
  </FormControl>
);

export default FormTimeRangeField;
