import "./App.css";
import { Box, ChakraProvider } from "@chakra-ui/react";

import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { BcgProvider } from "./util/BcgProvider";
import { theme } from "./themes/theme";
import { AppContextProvider } from "./util/AppContextProvider";
import { useEffect } from "react";
import { gradientBackground } from "./themes/Button";
import { datadogLogs } from "@datadog/browser-logs";
let isDatadogInitialized = false;

function AppWithContext() {
  // const { blackAndWhite } = useAppContext();

  useEffect(() => {
    if (isDatadogInitialized) return;
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
      site: "us5.datadoghq.com",
      service: "red-tees",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.NODE_ENV,

      beforeSend(event, context) {
        event.service = "red-tees";
        event.hostname = "red-tees";
        return true;
      },
    });
    isDatadogInitialized = true;
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Box
        position="fixed"
        width={"100vw"}
        height={"100vh"}
        // objectFit="cover"
        backgroundImage={gradientBackground}
        // objectPosition={'center'}
        // bgRepeat={'no-repeat'}
        zIndex={-1}
      />
      {/* <Image
        position="fixed"
        width={"100vw"}
        height={"100vh"}
        objectFit="cover"
        filter={blackAndWhite ? "grayscale(100%)" : "none"}
        src={
          "https://w0.peakpx.com/wallpaper/225/775/HD-wallpaper-golf-course-course-sandtrap-grass-golf.jpg"
        }
        objectPosition={"center"}
        bgRepeat={"no-repeat"}
        zIndex={-1}
      /> */}
      <BcgProvider>
        <RouterProvider router={router} />
      </BcgProvider>
    </ChakraProvider>
  );
}

function App() {
  return (
    <AppContextProvider>
      <AppWithContext />
    </AppContextProvider>
  );
}

export default App;
